@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --dark-black:  #121212;
  --black-black: #070707;
  --dark-gray: #282828;
  --dark-medium: #363636;
  --dark-light: #969696;
  --dark-white: #EEEEEE;
  --heart-red: #AA0505;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: var(--dark-white) !important;
  background-color: var(--black-black) !important;
}

instafont {
  font-family: freight-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.post-header {
  display: flex;
}

.hidden-link-black {
  color: var(--dark-black) !important;
  text-decoration: none !important;
}

.hidden-link-white {
  color: var(--dark-white) !important;
  text-decoration: none !important;
}

@keyframes like {
  0%   {color: var(--heart-red);}
  100%  {color: var(--dark-white);}
}

.heart-of-darkness {
  cursor: pointer;
}

.heart-like {
  animation-duration: 2s;
  animation-name: like;
  cursor: pointer;
}

.commenter {
  padding-right: 0 !important;
}

.comment {
  padding-left: 0 !important;
}

.responsive-image {
  max-width: 100%;
  height: 50%;
}

.square-card-image {
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
}

.square-card-image img {
  position: absolute;
}

.fixed-header, .fixed-footer{
  width: 100%;
  z-index: 999;
  max-height: 50px;
  position: fixed;        
  background: var(--dark-gray);
  color: var(--dark-white);
  height: 10% !important;
  display: flex;
}

.fixed-header{
  top: 0;
}
.fixed-footer{
  bottom: 0;
}

.main-content {
  display: flex;
  padding-top: 75px;
  padding-bottom: 10%;
}

.main-nav {
  background-color: var(--dark-gray);
}

@font-face {
  font-family: 'Billabong';
  font-style: normal;
  font-weight: normal;
  src: local('Billabong'), url('./media/fonts/Billabong.woff') format('woff');
}

.navbar-brand {
  font-family: 'Billabong','Roboto';
  font-weight: normal;
  font-size: 30px !important;
  color: var(--dark-white);
  padding-bottom: none !important;
}

.card-header {
  background-color: var(--dark-black) !important;
}

.card-footer {
  background-color: var(--dark-black) !important;
}

.white-icon {
  color: var(--dark-white) !important;
  cursor: pointer;
}

.black-icon {
  color: var(--dark-black) !important;
  cursor: pointer;
}

.card {
  background-color: var(--black-black) !important;
}

.post-sub-comment {
  color: var(--dark-light) !important;
  cursor: pointer;
}

.modal-content {
  background-color: var(--dark-black) !important;
}

.modal-header {
  border-bottom: none !important;
}

.close {
  text-shadow: none !important;
  color: var(--dark-white) !important;
  opacity: 1 !important;
}

.nav-icon,
.combined-nav-icon span .inner-nav-icon,
.combined-nav-icon .inner-nav-text {
  color: var(--dark-white) !important;
}

.nav-icon :hover,
.combined-nav-icon:hover span .inner-nav-icon,
.combined-nav-icon:hover .inner-nav-text {
  color: var(--dark-light) !important;
}

hr {
  background-color: var(--dark-light) !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

a {
  text-decoration: none !important;
  color: var(--dark-white) !important;
}

.icon-disabled {
  color: var(--dark-gray) !important;
  pointer-events: none !important;
}

.btn-secondary {
  background-color: var(--dark-gray) !important;
  border: none !important;
}

.btn-secondary:hover {
  background-color: var(--dark-black) !important;
}

.disabled {
  background-color: var(--dark-black) !important;
}

.profile-photo {
  border-radius: 100%;
  width: 60px;
}

.post-header {
  display: flex;
  align-items: center;
}

.post-identifiers {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}